import { ActivityCategoryType } from '../store/activity-categories.state';
import { Translatable, Translation } from './translation.model';

/**
 * Book API can use book_schema parameter to specify which information is needed
 * - simple: has a reduced set of properties, used most of the times
 * - metadata: simple but adding some specific metadata
 * - contentDashboard: was created for Insights (internal backoffice)
 * - only return the ID. Might not be used here
 */
export enum BookSchema {
  Simple = 'simple',
  Metadata = 'metadata',
  ContentDashboard = 'contentDashboard',
  OnlyId = 'onlyId',
}

export interface BookRequestResponse {
  totalCount: number;
  count: number;
  offset: number;
  limit: number;
  books: Book[];
}

// For favorites / reading & finished status parameter, the response does only return the array of uuids
export interface UserBookRequestResponse {
  totalCount: number;
  count: number;
  offset: number;
  limit: number;
  books: Array<string>;
}

// This interface has been simplified compared to what the backend return
// This is deliberate to improve visibility, as the interface is very complex
export interface Book {
  uuid: string;
  version: number;
  title: string;
  description: Translatable;
  totalLikes: number;
  timesOpened: number;
  coverImagePath: string;
  readLevel?: {
    name: string;
    level: string;
    translatableName: Translatable;
  };

  authors: Array<{ name: string }>;
  publishers: Array<{ name: string }>;
  translators: Array<{ name: string }>;
  illustrators: Array<{ name: string }>;
  activities: Array<BookActivity>;
  coverUrl: string;
}

export interface BookChapters {
  chapters: Array<BookChapter>;
  tableOfContents: Array<{
    index: number;
    title: string;
    itemId: string;
  }>;
}
export interface BookChapter {
  index: number;
  spineId: string;
  itemId: string;
}

export interface BookPage {
  currentPage: number;
  totalPages: number;
  pageHtml?: string; // TODO temp fix. Should not be an optional. See https://worldreader.atlassian.net/browse/B20CP-501
  aesKey?: string; // TODO temp fix. Should not be an optional. See https://worldreader.atlassian.net/browse/B20CP-501
}

export interface BookActivity {
  id: number;
  type: string;
  title: string;
  bookUuid: string;
  position: number;
  createdAt: string;
  placement: 'Pre' | 'Post';
  updatedAt: string;
  description: string;
  englishTitle?: string;
  activitySkills: Array<{
    id: number;
    activityCategoryCode: string;
    name: {
      default: string;
      translations: Array<Translation>;
    };
    createdAt: string;
    updatedAt: string;
  }>;
  translationType?: string;
  englishDescription?: string;
  activityCategoryCode: ActivityCategoryType;
  translatablePlacement: {
    default: string;
    translations: Array<Translation>;
  };
}

export interface BookCategory {
  slug: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  name: {
    default: string;
    translations: Translation[];
  };
  color: string;
  id: number;
  iconUrl: string;
}
