import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ReaderFontFamily,
  ReaderFontSize,
  ReaderLetterSpacing,
  ReaderLineSpacing,
  ReaderTheme,
} from '../pages/book-reader/components/book-reader.model';
import { PreferencesService } from '../services/preferences.service';
import { STORAGE_BOOK_READER_SETTINGS } from './constants';

interface BookReaderSettings {
  customSettings: boolean;
  theme: ReaderTheme;
  brightness: number;
  fontFamily: ReaderFontFamily;
  fontSize: ReaderFontSize;
  lineSpacing: ReaderLineSpacing;
  letterSpacing: ReaderLetterSpacing;
  onboardingDone: boolean;
}

@Injectable({ providedIn: 'root' })
export class BookReaderSettingsState {
  constructor(private preferenceService: PreferencesService) {}

  private readonly STORAGE_KEY = STORAGE_BOOK_READER_SETTINGS;
  private readonly initialSettings: BookReaderSettings = {
    customSettings: false,
    theme: ReaderTheme.DEFAULT,
    brightness: 100,
    fontFamily: ReaderFontFamily.MONTSERRAT,
    fontSize: ReaderFontSize.S,
    lineSpacing: ReaderLineSpacing.DEFAULT,
    letterSpacing: ReaderLetterSpacing.DEFAULT,
    onboardingDone: false,
  };

  bookReaderOptions$!: BehaviorSubject<BookReaderSettings>;

  async initialize(): Promise<void> {
    const storedSettings = await this.get();
    this.bookReaderOptions$ = new BehaviorSubject<BookReaderSettings>(storedSettings || this.initialSettings);

    if (!storedSettings) {
      await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(this.initialSettings));
    }

    return Promise.resolve();
  }

  private async get(): Promise<BookReaderSettings | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<BookReaderSettings>): Promise<void> {
    const updatedOptions: BookReaderSettings = {
      ...this.bookReaderOptions$?.value,
      ...settings,
    };

    this.bookReaderOptions$.next(updatedOptions);
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }
}
