import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PreferencesService } from '../services/preferences.service';
import { STORAGE_BOOK_FILTERS } from './constants';
import { Grade } from '../models/projects.model';
import { Language } from '../providers/language.provider';
import { DefaultProjectState } from './default-project.state';
import { BooksProvider } from '../providers/books.provider';
import { ProfileState } from './profile.state';

export interface BookFilters {
  grades: Array<Grade>;
  languages: Array<Language>;
  selectedGrade: Grade | null;
  selectedLanguage: Language | null;
}

@Injectable({ providedIn: 'root' })
export class BookFiltersState {
  constructor(
    private defaultProjectState: DefaultProjectState,
    private preferenceService: PreferencesService,
    private booksProvider: BooksProvider,
    private profileState: ProfileState,
  ) {}

  private readonly STORAGE_KEY = STORAGE_BOOK_FILTERS;

  bookFilters$!: BehaviorSubject<BookFilters>;

  async initialize(): Promise<void> {
    const storedSettings = await this.get();
    const settings = storedSettings || (await this.getInitialSettings());

    if (!this.bookFilters$) {
      this.bookFilters$ = new BehaviorSubject<BookFilters>(settings);
    }

    await this.set(settings);

    return Promise.resolve();
  }

  private async get(): Promise<BookFilters | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<BookFilters>): Promise<void> {
    const updatedOptions: BookFilters = {
      ...this.bookFilters$?.value,
      ...settings,
    };

    this.bookFilters$?.next(updatedOptions);
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }

  async getInitialSettings(): Promise<BookFilters> {
    const languages = await lastValueFrom(this.booksProvider.getBookLanguages());
    const grades = this.defaultProjectState.defaultProject$.value.grades;

    return {
      grades,
      languages,
      selectedLanguage: null,
      selectedGrade: grades.find(grade => grade.maxAge === this.profileState.currentProfile$?.value?.maxAge) ?? null,
    };
  }

  async reset(): Promise<void> {
    await this.preferenceService.remove(this.STORAGE_KEY);
    await this.initialize();
  }
}
