export enum ReaderTheme {
  DEFAULT = 'default',
  LIGHT_GREY = 'light-grey',
  DARK_GREY = 'dark-grey',
}

export enum ReaderFontFamily {
  MONTSERRAT = 'montserrat',
  POPPINS = 'poppins',
  LORA = 'lora',
}

export enum ReaderFontSize {
  XS = 0.8,
  S = 1,
  M = 1.3,
  L = 1.5,
  XL = 1.7,
}

export enum ReaderLineSpacing {
  DEFAULT,
  LARGE,
  XLARGE,
}

export enum ReaderLetterSpacing {
  DEFAULT = 1,
  LARGE = 2,
  XLARGE = 3,
}
